import './About.css';

import Navigation from '../Components/Navigation';
import Stack from 'react-bootstrap/Stack';

function About() {
  return (
    <div>
      <Navigation />
      <Stack>
        <div>This site is maintained by volounteers.</div>
      </Stack>
    </div>
  );
}

export default About;
