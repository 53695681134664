import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './Pages/App';
import About from './Pages/About';

import 'bootstrap/dist/css/bootstrap.min.css';
import Health from './Pages/Health';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/health-probe",
    element: <Health />,
  },
  {
    path: "/about",
    element: <About />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);