import './App.css';

import Navigation from '../Components/Navigation';
import CameraViewer from '../Components/CameraViewer';

function App() {

  const videoId:string = process.env.REACT_APP_CAMERA_VIDEO_ID ?? ""

  return (
    <div>
      <Navigation />
      <CameraViewer videoId={videoId} />
    </div>
  );
}

export default App;
