import React, { FC } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import YouTube, { YouTubeProps } from 'react-youtube';

interface CameraViewerProps {
    videoId: string;
}

const CameraViewer: FC<CameraViewerProps> = ({ videoId }) => {
    const onPlayerReady: YouTubeProps['onReady'] = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    const opts: YouTubeProps['opts'] = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };

    return (
        <Container fluid>
            <Row>
                <Col>
                    <YouTube videoId={videoId} opts={opts} onReady={onPlayerReady} />
                </Col>
            </Row>
        </Container>
    );
}
export default CameraViewer;